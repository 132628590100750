.project {
    border: 1px solid rgb(0, 0, 0);
    border-radius: 20px;
    overflow: hidden;
    /* height: 500px; */
    display: flex;
    flex-direction: column;
    cursor: pointer;
    /* background-color: #1b1b1b; */
    transition: all .2s ease-in-out;
}

.project:hover {
    transform: scale(1.01);
}

.project > img {
    height: 350px;
    object-fit: cover;
}

.project-details {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 20px;
    padding-right: 20px;
}

.project-name {
    font-size: 1.5rem;
    /* font-weight: 100; */
}

.project-name > p {
    font-family: 'poppins-bold';
}

.project-description {
    padding-top: 10px;
    font-size: 1.2rem;
    font-weight: 300;
}

.project-link {
    text-decoration: none;
    /* color: #fff; */
    color: rgb(30, 30, 30);
}

.language-icons {
    display: flex;
    align-items: center;
    padding-top: 5px;
    /* padding-bottom: 10px; */
}

.icon {
    margin-right: 10px;
}