.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid rgb(0, 0, 0);
}

.header-title {
    font-size: 1.5rem;
    font-weight: bold;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.header-title:hover{
    color: rgb(206, 101, 255);
}

.links {
    display: flex;
    align-items: center;
    font-weight: 800;
}

.links > p {
    margin: 0 0.5rem;
    /* border: 1px solid #fff; */
}

.links > p:hover {
    cursor: pointer;
    color: rgb(206, 101, 255);
    transition-duration: 500ms;
}

.header {
    /* position: fixed; */
    width: 100%;
    background-color: rgba(25, 25, 25, 0);
    z-index: 1;
}

.header-title > img {
    height: 50px;
    /* border: 1px solid #fff; */
    margin-top: 5px;
}

.logo {
    -webkit-filter: invert(1);
    filter: invert(1);
    transition-timing-function: ease;
}

/* when the mouse is over the logo class, make it spin */
.logo:hover {
    -webkit-animation: spin 2s linear infinite;
    animation: spin 2s linear infinite;
}