.footer-container {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: center;
    padding: 0.5rem 1rem;
    /* border: 1px solid #fff; */
}

.footer {
    padding-top: 3rem;
    padding-bottom: 3rem;
    border-top: 1px solid rgb(22, 22, 22);
}

.footer-h1 {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
}