.hero{
    height: 20vh;
    /* border: 1px solid #fff; */
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    /* padding-top: 100px; */
}

.hero-container {
    /* border: 1px solid #fff; */
}

@media (min-width: 1200px) {
    .hero-container {
        /* margin of 200px on the sides */
        margin: 0rem 10rem;
    }
}

.hero-title {
    font-size: 1.5rem;
    font-weight: bold;
    /* align in the center */
    /* text-align: center; */
    padding: 0px 0px 10px 0px;
}

.hero-description {
    font-size: 1.5rem;
    font-weight: 500;
    /* text-align: center; */
    /* font-weight: bold; */
}

.hero-title::before {
    content: "<h1>";
    font-size: 10px;
    margin-left: -10px;
    font-weight: 300;
}

.hero-title::after {
    content: "<h1>";
    font-size: 10px;
    margin-left: -10px;
    font-weight: 300;
}
