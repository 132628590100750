

@font-face {
    font-family: 'cascSemi';
    src: url("./fonts/static/CascadiaCode-SemiBold.otf") format('opentype');
}

@font-face {
    font-family: 'cascBold';
    src: url("./fonts/static/CascadiaCode-Bold.otf") format('opentype');
}

@font-face {
    font-family: 'poppins-light';
    src: url(./fonts/Poppins/Poppins-Light.ttf) format('truetype');
}

@font-face {
    font-family: 'poppins-regular';
    src: url(./fonts/Poppins/Poppins-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'poppins-medium';
    src: url(./fonts/Poppins/Poppins-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'poppins-semibold';
    src: url(./fonts/Poppins/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
    font-family: 'poppins-bold';
    src: url('./fonts/Poppins/Poppins-Bold.ttf') format('truetype');
}

body {
  margin: 0;
    font-family: 'poppins-bold';
  /* -webkit-font-smoothing: antialiased; */
  /* -moz-osx-font-smoothing: grayscale; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    margin: 0;
    padding: 0;
    /* border: red solid 1px; */
}